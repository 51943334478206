import axios from "axios";

const apiClient = ({ method = "get", url = "", data = {}, params = {} }) => {
  axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
  const token = localStorage.getItem("token");
  return new Promise(async (resolve, reject) => {
    try {
      let headers = { Authorization: `Bearer ${token}` };
      let response = await axios({ method, url, data, headers, params });
      if (response.data.success) {
        resolve(response.data);
      } else {
        reject(new Error(response.data.error ?? response.data.message));
      }
    } catch (error) {
      if (error?.response?.status === 401) {
        localStorage.clear();
        window.location = "/auth/signin";
      }
      reject(error);
    }
  });
};

export default apiClient;
