// Chakra imports
import { Box, Flex, Icon, Image, Text } from "@chakra-ui/react";
import PropTypes from "prop-types";
import React from "react";
import Footer from "components/footer/FooterAuth";
import FixedPlugin from "components/fixedPlugin/FixedPlugin";

function AuthIllustration(props) {
  const { children } = props;
  // Chakra color mode
  return (
    <Box pos="relative">
      <Image
        w="100vw"
        h="100vh"
        left="0px"
        top="0px"
        position="fixed"
        src="/assets/img/dashboards/Debit.png"
      />
      <Flex position="relative" minH="100vh" bg="whiteAlpha.100" backdropFilter="blur(15px)" h="max-content">
        <Flex
          h={{
            sm: "initial",
            md: "unset",
            lg: "100vh",
            xl: "97vh",
          }}
          w="100%"
          pt={{ sm: "50px", md: "0px" }}
          px={{ lg: "30px", xl: "0px" }}
          ps={{ xl: "70px" }}
          direction="column"
        >
          {children}
          <Footer />
        </Flex>
        <FixedPlugin />
      </Flex>
    </Box>
  );
}
// PROPS

AuthIllustration.propTypes = {
  illustrationBackground: PropTypes.string,
  image: PropTypes.any,
};

export default AuthIllustration;
