import {
  Box,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useBoolean,
  useToast,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import Table from "components/Table";
import useDispatch from "hooks/dispatch";
import apiClient from "api/index";
import { formateDate, getPromoAmount, getStatus } from "helpers";
import { NumericFormat } from "react-number-format";
import Button from "components/base/button";
import { STATUS } from "constant/index";

export default function Transactions() {
  const { state, dispatch } = useDispatch();
  const [open, setOpen] = useBoolean();
  const [consult, setConsult] = useBoolean();
  const toast = useToast();

  const columns = [
    {
      Header: "Reference Number",
      accessor: "refNumber",
    },
    {
      Header: "Sender",
      accessor: "client",
      Cell: ({ value }) => {
        return value?.fullName ?? "";
      },
    },
    {
      Header: "Sender Phone",
      accessor: "client2",
      Cell: ({ row: { original } }) => {
        return original?.client.phone ?? "";
      },
    },
    {
      Header: "Wallet",
      accessor: "contact2",
      Cell: ({ row: { original } }) => {
        return original.contact?.wallet ?? "";
      },
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ row }) => getPromoAmount(row.original),
    },
    {
      Header: "Pay Amount",
      accessor: "paidAmount",
      Cell: ({ row }) => getPromoAmount(row.original, false),
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => getStatus(value).name,
    },

    {
      Header: "Date",
      accessor: "created",
      Cell: ({ value }) => {
        return formateDate(value);
      },
    },
    {
      Header: "",
      accessor: "action",
      Cell: ({ row: { original } }) => {
        return (
          <Flex gap="10px">
            <Button
              onClick={() => {
                dispatch({ consultTransaction: original });
                setConsult.on();
              }}
            >
              View
            </Button>
          </Flex>
        );
      },
    },
  ];

  const validateTransaction = async () => {
    try {
      dispatch({ validating: true });
      const transactionId = state.consultTransaction?._id;
      const url = state.pay
        ? "/transactions/" + transactionId + "/pay"
        : "/transactions/" + transactionId;
      await apiClient({ url: url, method: "put" });
      dispatch({ validating: false, selectedTransaction: null, pay: null });
      toast({
        title: "Transaction validated",
        description: "Transaction has been validated successfully!",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      setOpen.off();
      setConsult.off();
      getTransactions();
    } catch (error) {
      dispatch({ validating: false, selectedTransaction: null, pay: null });
      toast({
        description: "An error occured validating transaction",
        title: "An error occured",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const getTransactions = async () => {
    try {
      dispatch({ loading: true });
      const response = await apiClient({
        method: "get",
        url: "/transactions",
        params: { status: [STATUS.PENDING, STATUS.INITIATED] },
      });
      const transactions = response.data;
      dispatch({ transactions, loading: false });
    } catch (error) {
      dispatch({ loading: false });
    }
  };
  useEffect(() => {
    getTransactions();
  }, []);

  const transaction = state.consultTransaction || {};

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Table
        columns={columns}
        data={state.transactions}
        loading={state.loading}
      />

      <AlertDialog isOpen={open} onClose={setOpen.off}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm validation
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to validate this transaction?
            </AlertDialogBody>

            <AlertDialogFooter display="flex" alignItems="center" gap="4">
              <Button
                onClick={validateTransaction}
                loading={state.validating}
                loadingText="Loading..."
              >
                Yes
              </Button>
              <Button
                bg="red.500"
                _hover={{ bg: "red.400" }}
                disabled={state.validating}
                onClick={setOpen.off}
              >
                No
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal isOpen={consult} onClose={setConsult.off}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Consult Transaction</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing="4">
              <Card title={"Transaction Info"} w="full" pl="4">
                <Info title="Reference" desc={transaction.refNumber} />
                <Info
                  title="Amount"
                  desc={
                    getPromoAmount(transaction)
                  }
                />
                <Info
                  title="Recieving Amount"
                  desc={
                    <Text fontSize="14px">
                      {getPromoAmount(transaction, false)}
                    </Text>
                  }
                />
                <Info
                  title="Fee"
                  desc={
                    <NumericFormat
                      value={parseInt(transaction.fee)}
                      style={{ color: "#422AFB" }}
                      displayType="text"
                      thousandSeparator=" "
                      suffix={" " + transaction.currency}
                    />
                  }
                />
                <Info
                  title="Status"
                  desc={getStatus(transaction.status).name}
                />
                <Info title="Date" desc={formateDate(transaction.created)} />
              </Card>

              <Card title="Sender Info">
                <Info title="Name" desc={transaction.client?.fullName} />
                <Info title="Email" desc={transaction.client?.email} />
                <Info title="Phone" desc={transaction.client?.phone} />
              </Card>
              <Card title="Reciever's Info">
                <Info title="Name" desc={transaction.contact?.fullName} />
                <Info title="Email" desc={transaction.contact?.email} />
                <Info title="Phone" desc={transaction.contact?.phone} />
                <Info title="Wallet" desc={transaction.contact?.wallet} />
              </Card>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              mr={3}
              onClick={setConsult.off}
            >
              Close
            </Button>

            <Button
              onClick={() => {
                setOpen.on();
              }}
            >
              Mark as paid
            </Button>
            {/* <Button
              onClick={() => {
                dispatch({ selectedTransaction: transactionId, pay: true });
                setOpen.on();
              }}
            >
              Pay Now
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

const Info = ({ title, desc }) => {
  if (!desc) return <></>;
  return (
    <HStack justifyContent="space-between" w="100%">
      <Text fontWeight="semibold">{title}:</Text>
      <Text>{desc}</Text>
    </HStack>
  );
};

const Card = ({ title, children }) => {
  return (
    <VStack w="100%" bg="gray.200" p="4" rounded="md"  spacing="4" px="4" alignItems="start">
      <Text fontWeight="700" fontSize="lg">
        {title}
      </Text>
      <VStack w="full" pl="4">
        {children}
      </VStack>
    </VStack>
  );
};
