import {
  Box,
  Flex,
  Icon,
  SimpleGrid,
  Spinner,
  Text,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useEffect } from "react";
import { MdAttachMoney, MdBarChart, MdPayment, MdPayments, MdPeople } from "react-icons/md";
import useDispatch from "hooks/dispatch";
import apiClient from "api/index";
import { NumericFormat } from "react-number-format";
import Table from "components/Table";
import { formateDate, getPromoAmount, getStatus } from "helpers/index";
import Card from "components/card/Card";
import { Link } from "react-router-dom";

export default function UserReports() {
  const { state, dispatch } = useDispatch({ loading: true });
  const toast = useToast();
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const fetchDashboard = async () => {
    try {
      dispatch({ loading: true });
      const response = await apiClient({ url: "/dashboard" });
      let data = response.data;
      let pieData = [data.totalSuccess, data.totalPending, data.totalFailed];
      dispatch({ ...data, pieData, loading: false });
    } catch (error) {
      dispatch({ loading: false });
      toast({ title: "An error occured", duration: 9000, status: "error" });
    }
  };

  const columns = [
    {
      Header: "Reference Number",
      accessor: "refNumber",
    },
    {
      Header: "Sender",
      accessor: "client",
      Cell: ({ value }) => {
        return value?.fullName ?? "";
      },
    },
    {
      Header: "Sender Phone",
      accessor: "client2",
      Cell: ({ row: { original } }) => {
        return original?.client.phone ?? "";
      },
    },
    {
      Header: "Wallet",
      accessor: "contact2",
      Cell: ({ row: { original } }) => {
        return original.contact?.wallet ?? "";
      },
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ row }) => getPromoAmount(row.original),
    },
    {
      Header: "Pay Amount",
      accessor: "paidAmount",
      Cell: ({ row }) => getPromoAmount(row.original, false),
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => getStatus(value).name,
    },

    {
      Header: "Date",
      accessor: "created",
      Cell: ({ value }) => {
        return formateDate(value);
      },
    },
  ];

  useEffect(() => {
    fetchDashboard();
  }, []);
  return (
    <>
      {state.loading ? (
        <Flex
          h="80vh"
          pt={{ base: "130px", md: "80px", xl: "80px" }}
          justifyContent="center"
          alignItems="center"
        >
          <Spinner size="xl" />
        </Flex>
      ) : (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <SimpleGrid
            columns={{ base: 1, md: 2, lg: 3, "2xl": 5 }}
            gap="20px"
            mb="20px"
          >
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={MdBarChart}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Earnings"
              value={<DisplayAmount value={state.totalEarning} />}
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon
                      w="32px"
                      h="32px"
                      as={MdAttachMoney}
                      color={brandColor}
                    />
                  }
                />
              }
              name="Earnings this month"
              value={<DisplayAmount value={state.monthTotalEarning} />}
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon w="32px" h="32px" as={MdPayment} color={brandColor} />
                  }
                />
              }
              name="Total Transactions"
              value={state.totalTransactions}
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg={boxBg}
                  icon={
                    <Icon w="32px" h="32px" as={MdPayments} color={brandColor} />
                  }
                />
              }
              name="Pending Transactions"
              value={state.totalPendingTransactions}
            />
            <MiniStatistics
              startContent={
                <IconBox
                  w="56px"
                  h="56px"
                  bg="linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)"
                  icon={<Icon w="28px" h="28px" as={MdPeople} color="white" />}
                />
              }
              name="Total Clients"
              value={state.totalClients}
            />
          </SimpleGrid>

          <Card
            direction="column"
            w="100%"
            px="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
          >
            <Flex px="25px" justify="space-between" align="center">
              <Text
                color={textColor}
                fontSize="22px"
                fontWeight="700"
                lineHeight="100%"
              >
                Pending Transactions
              </Text>
              <Box>
                <Link to="/admin/transaction">
                  <button>See all</button>
                </Link>
              </Box>
            </Flex>
            <Table columns={columns} data={state.pendingTransactions} />
          </Card>

          {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
            <TotalSpent />
            <WeeklyRevenue />
          </SimpleGrid> */}

          {/* <Grid templateColumns="repeat(3, 1fr)" gap="20px" mb="20px">
            <GridItem colSpan={2}>

            </GridItem>
            <GridItem h="full">
              <Piechart data={state.pieData} />
            </GridItem>
          </Grid> */}
        </Box>
      )}
    </>
  );
}

const DisplayAmount = ({ value = 0 }) => {
  return (
    <NumericFormat
      displayType="text"
      prefix="$ "
      thousandSeparator=","
      value={parseFloat(value).toFixed(1)}
    />
  );
};
