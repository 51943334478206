import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdPerson,
  MdHome,
  MdSettings,
  MdPeopleAlt,
  MdMoney,
  MdDataUsage,
  MdMoneyOff,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import Users from "views/admin/users";
import Clients from "views/admin/clients";
import Transactions from "views/admin/transactions";
import Configurations from "views/admin/configurations";
import SignIn from "views/auth/signIn";
import TransactionsHistory from "views/admin/transactions/history";
import Consult from "views/admin/transactions/consult";
import Coupons from "views/admin/coupons";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    showSideBar: true,
    path: "/dashboard",
    icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
    component: MainDashboard,
  },
  {
    name: "Configurations",
    layout: "/admin",
    path: "/config",
    icon: (
      <Icon
        as={MdSettings}
        width='20px'
        height='20px'
        color='inherit'
      />
    ),
    component: Configurations,
    showSideBar: true,
  },
  {
    name: "Users",
    layout: "/admin",
    icon: <Icon as={MdPeopleAlt} width='20px' height='20px' color='inherit' />,
    path: "/users",
    component: Users,
    showSideBar: true,
  },
  {
    name: "Clients",
    layout: "/admin",
    path: "/clients",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Clients,
    showSideBar: true,
  },
  {
    name: "Consult",
    layout: "/admin",
    path: "/transaction/:id",
    component: Consult,
  },
  {
    name: "Coupons",
    layout: "/admin",
    path: "/coupons",
    icon: <Icon as={MdMoneyOff} width='20px' height='20px' color='inherit' />,
    component: Coupons,
    showSideBar: true,
  },
  {
    name: "Pending Transactions",
    layout: "/admin",
    path: "/transaction",
    icon: <Icon as={MdMoney} width='20px' height='20px' color='inherit' />,
    component: Transactions,
    showSideBar: true,
  },
  {
    name: "Transactions History",
    layout: "/admin",
    path: "/history",
    icon: <Icon as={MdDataUsage} width='20px' height='20px' color='inherit' />,
    component: TransactionsHistory,
    showSideBar: true,
  },
  {
    name: "SignIn",
    layout: "/auth",
    path: "/signin",
    component: SignIn,
  }
];

export default routes;
