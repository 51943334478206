import React from "react";
// Chakra imports
import { Box, Flex, Heading, Text, useColorModeValue } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as yup from "yup";
import DefaultAuth from "layouts/auth/Default";
import { useAuth } from "contexts/auth";
import Input from "components/base/input";
import PasswordField from "components/base/passwordfield";
import Button from "components/base/button";

function SignIn() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";

  const { loginUser, userLoading, loginError } = useAuth();
  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Enter your email"),
    password: yup.string().required("Enter your password"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: loginUser,
  });

  return (
    <DefaultAuth>
      <Flex
        w="max-content"
        mx="auto"

        p="30px"
        borderRadius="20px"
        justifyContent="center"
        mb={{ base: "30px", md: "60px" }}
        mt={{ base: "40px", md: "25vh" }}
        flexDirection="column"
        bg="whiteAlpha.700"
        backdropFilter="blur(10px)"
      >
        <Box me="auto">
          <Heading color={textColor} fontSize="36px" mb="10px">
            Sign In
          </Heading>
          <Text
            mb="36px"
            ms="4px"
            color="black"
            fontWeight="400"
            fontSize="md"
          >
            Enter your email and password to sign in!
          </Text>
        </Box>
        <Flex
          zIndex="2"
          direction="column"
          w={{ base: "100%", md: "420px" }}
          maxW="100%"
          background="transparent"
          borderRadius="15px"
        >
          <Input
            label="Email"
            value={formik.values.email}
            name="email"
            error={formik.errors.email || loginError}
            onChange={formik.handleChange}
            borderColor="blackAlpha.500"
          />
          <PasswordField
            label="Password"
            value={formik.values.password}
            name="password"
            error={formik.errors.password}
            onChange={formik.handleChange}
            borderColor="blackAlpha.500"
          />
          <Button onClick={formik.submitForm} loading={userLoading}>
            Sign In
          </Button>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
