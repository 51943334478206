import { HStack, Text } from "@chakra-ui/react";
import { STATUS } from "constant";
import moment from "moment";
import { NumericFormat } from "react-number-format";

export const formateDate = (date) => {
  if (!date) return "";
  return moment(new Date(date)).format("DD MMM YYYY HH:MM");
};

export const getStatus = (s) => {
  if (!s) {
    return {};
  }
  const [name, status] = Object.entries(STATUS).find((b) => b[1] === s);

  return { name, status };
};

export const getPromo = (amount, coupon = {}) => {
  let promoAmount = 0;
  if (coupon.type === "P") {
    promoAmount = parseFloat(
      (Number(coupon.value) / 100) * Number(amount)
    ).toFixed(2);
  } else {
    promoAmount = coupon.value;
  }

  if (coupon.maxValue & promoAmount > coupon.maxValue) {
    return Number(coupon.maxValue)
  }
  return  Number(promoAmount);
};

export const getPromoAmount = (transaction = {}, isAmount = true) => {
  let coupon = transaction.coupon;
  let amount = isAmount ? transaction.amount : transaction.paidAmount;
  let currency = isAmount ? transaction.currency : "XAF"; 

  if (coupon && ((isAmount && coupon.affect === "S") || (!isAmount && coupon.affect === "R"))) {
    let promo = isAmount ? amount - getPromo(amount, coupon) : amount + getPromo(amount, coupon)
    return (
      <Text display="flex" gap="1">
        <NumericFormat
          value={ isAmount ? Number(promo).toFixed(2) : Number(promo).toFixed(0)}
          thousandSeparator=" "
          displayType="text"
          style={{ color: "#422AFB" }}
          suffix={" " + currency}
        />
        
        (
        <NumericFormat
          value={ isAmount ? Number(amount).toFixed(2) : Number(amount).toFixed(0)}
          thousandSeparator=" "
          displayType="text"
          style={{ textDecoration: "line-through", margin: 0, padding: 0 }}
          suffix={" " + currency}
        />
        )
      </Text>
    );
  }
  return (
    <NumericFormat
      value={Number(amount).toFixed(2)}
      thousandSeparator=" "
      displayType="text"
      style={{ color: "#422AFB" }}
      suffix={" " + currency}
    />
  );
};
