import {
  FormControl,
  FormLabel,
  Select as ChakraSelect,
  useColorModeValue,
  Text,
  FormHelperText,
  Box,
} from "@chakra-ui/react";
import React from "react";

export default function Select({
  label,
  hideLabel,
  required,
  placeholder,
  name,
  error,
  options = [],
  ...rest
}) {
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const borderColor = useColorModeValue(
    "secondaryGray.100",
    "rgba(135, 140, 189, 0.3)"
  );
  return (
    <FormControl>
      {!hideLabel && (
        <FormLabel
          display="flex"
          ms="4px"
          fontSize="sm"
          fontWeight="500"
          color={textColor}
          mb="8px"
        >
          {label} {required && <Text color={brandStars}>*</Text>}
        </FormLabel>
      )}
      <Box mb="24px">
        <ChakraSelect
          isRequired={required}
          fontSize="sm"
          name={name}
          ms={{ base: "0px", md: "0px" }}
          type="email"
          placeholder={placeholder}
          fontWeight="500"
          borderColor={borderColor}
          size="lg"
          {...rest}
        >
          <option value="">Select</option>
          {options.map((o) => (
            <option value={o.value}>{o.label}</option>
          ))}
        </ChakraSelect>
        {error && <FormHelperText color="red.500">{error}</FormHelperText>}
      </Box>
    </FormControl>
  );
}
