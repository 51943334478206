import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Table from "components/Table";
import useDispatch from "hooks/dispatch";
import apiClient from "api/index";

export default function Users() {
  const {state, dispatch} = useDispatch()
  // Chakra Color Mode

  const columns = [
    {Header: "Full name", accessor: "fullName"},
    {Header: "Email", accessor: "email"},
    {Header: "Phone", accessor: "phone"},
    {Header: "Profile", accessor: "profile"},
    {Header: "Created", accessor: "created"},
  ]
  const fetchUsers = async () => {
    try {
      dispatch({loading: true})
      const response = await apiClient({method: "get", url: "/users"})
      const users = response.data;
      dispatch({users, loading: false})
    } catch (error) {
      dispatch({loading: false})
    }
  }
  useEffect(()=> {
    fetchUsers();
  }, [])
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Table
        columns={columns}
        data={state.users}
        loading={state.loading}
      />
    </Box>
  );
}
