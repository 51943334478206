import {
  Box,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  HStack,
  useBoolean,
  useToast,
  Text,
  IconButton,
  Tag,
  TagLabel,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import Table from "components/Table";
import useDispatch from "hooks/dispatch";
import apiClient from "api/index";
import Button from "components/base/button";
import Input from "components/base/input";
import Select from "components/base/select";
import { useFormik } from "formik";
import * as yup from "yup";
import { FaTrash } from "react-icons/fa";
import { useQuery } from "hooks/query";
import { useHistory } from "react-router-dom";

const couponTypeOptions = [
  { label: "Amount", value: "A" },
  { label: "Percentage", value: "P" },
];
const applyTo = [
  { label: "Reciever", value: "R" },
  { label: "Sender", value: "S" },
];

const status = [
  { text: "All", value: "all" },
  { text: "Active", value: "active" },
  { text: "Expired", value: "expired" },
  { text: "Deleted", value: "deleted" },
];
export default function Coupons() {
  const { state, dispatch } = useDispatch();
  const [consult, setConsult] = useBoolean();
  const [open, setOpen] = useBoolean();
  const toast = useToast();
  const query = useQuery();
  let history = useHistory();
  let currentStatus = query.get("status") || "active";
  const columns = [
    {
      Header: "Code",
      accessor: "code",
      Cell: ({ value }) => {
        return <Text color="green.500">{value}</Text>;
      },
    },
    {
      Header: "Type",
      accessor: "type",
      Cell: ({ value }) => (value === "P" ? "Percentage" : "Amount"),
    },
    {
      Header: "Apply to",
      accessor: "affect",
      Cell: ({ value }) => (value === "R" || !value ? "Reciever" : "Sender"),
    },
    {
      Header: "Min Amount",
      accessor: "minAmount",
    },
    {
      Header: "Value",
      accessor: "value",
      Cell: ({ row: { original } }) => {
        return (
          <Text color="green.500">
            {original.type === "P"
              ? original.value + "%"
              : original.value + "UNIT"}{" "}
            {original.maxValue && (
              <span>&nbsp; (MAX: {original.maxValue} UNIT)</span>
            )}
          </Text>
        );
      },
    },
    {
      Header: "Quantity",
      accessor: "quantity",
      Cell: ({ row: { original } }) => {
        let amount = original.quantity - original.useCount;
        return (
          <Text color={!amount ? "red.500" : "gray.600"}>
            {amount} of {original.quantity} left
          </Text>
        );
      },
    },
    {
      Header: "Validity",
      accessor: "validity",
    },
    {
      Header: "isActive",
      accessor: "isActive",
      Cell: ({ value }) => {
        return (
          <Text color={value ? "green.500" : "red.500"}>
            {value ? "Active" : "InActive"}
          </Text>
        );
      },
    },

    {
      Header: "Date",
      accessor: "created",
    },
    {
      Header: "",
      accessor: "action",
      Cell: ({ row: { original } }) => {
        return (
          <HStack>
            <IconButton
              onClick={() => {
                dispatch({ coupon: original });
                setOpen.on();
              }}
              colorScheme="red"
              icon={<FaTrash />}
            ></IconButton>
          </HStack>
        );
      },
    },
  ];

  const createCoupon = async (data) => {
    try {
      dispatch({ creating: true });
      let response = await apiClient({ data, method: "post", url: "/coupon" });
      if (!response.success) throw new Error("An error occured");
      toast({ title: "Coupon Created", duration: 9000, status: "success" });
      dispatch({ creating: false, currentCoupon: response.data });
      setConsult.off();
      fetchData();
    } catch (error) {
      dispatch({ creating: false });
      toast({ title: "An error occured", duration: 9000, status: "error" });
    }
  };

  const fetchData = async (status) => {
    try {
      dispatch({ loading: true });
      const response = await apiClient({
        method: "get",
        url: "/coupon",
        params: { status: status || currentStatus },
      });
      const coupons = response.data;
      dispatch({ coupons, loading: false });
    } catch (error) {
      dispatch({ loading: false });
    }
  };

  const deleteCoupon = async () => {
    const couponId = state.coupon?._id;
    try {
      dispatch({ deleting: true });
      let response = await apiClient({
        method: "delete",
        url: "/coupon/" + couponId,
      });
      setOpen.off();
      if (!response.success) throw new Error("An error occured");
      toast({ title: "Coupon Deleted", duration: 9000, status: "success" });
      dispatch({ deleting: false, currentCoupon: response.data });
      fetchData();
    } catch (error) {
      setOpen.off();
      dispatch({ deleting: false });
      toast({ title: "An error occured", duration: 9000, status: "error" });
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const validationSchema = yup.object({
    couponType: yup.string().required(),
    coupon: yup.number().required(),
    maxCoupon: yup.number(),
    minAmount: yup.number(),
    quantity: yup.number().required(),
    validity: yup.number().required(),
  });

  const formik = useFormik({
    initialValues: {
      validity: 7,
      quantity: 1,
      couponType: "P",
      affect: "R",
    },
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: createCoupon,
  });

  const handleSelect = (value) => {
    history.push(window.location.pathname + "?status=" + value);
    fetchData(value);
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <HStack justifyContent="space-between" alignItems="center" pb="4">
        <HStack>
          {status.map((s) => (
            <FilterButton
              value={s.value}
              onSelect={() => handleSelect(s.value)}
              current={currentStatus}
              key={s.value}
            >
              {s.text}
            </FilterButton>
          ))}
        </HStack>
        <Button onClick={setConsult.on} w="max-content">
          Add Coupon
        </Button>
      </HStack>
      <Table columns={columns} data={state.coupons} loading={state.loading} />

      <AlertDialog isOpen={open} onClose={setOpen.off}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Delete Coupon
            </AlertDialogHeader>
            <AlertDialogBody>
              Are you sure you want to delete this coupon?
            </AlertDialogBody>

            <AlertDialogFooter display="flex" alignItems="center" gap="4">
              <Button
                onClick={deleteCoupon}
                loading={state.deleting}
                loadingText="Deleting..."
              >
                Yes
              </Button>
              <Button
                bg="red.500"
                _hover={{ bg: "red.400" }}
                disabled={state.deleting}
                onClick={setOpen.off}
              >
                No
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Modal isOpen={consult} onClose={setConsult.off}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Coupon</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              <Select
                label="Coupon Type"
                value={formik.values.couponType}
                name="couponType"
                error={formik.errors.couponType}
                options={couponTypeOptions}
                onChange={formik.handleChange}
              />
              <Select
                label="Apply To"
                value={formik.values.affect}
                name="affect"
                error={formik.errors.affect}
                options={applyTo}
                onChange={formik.handleChange}
              />

              <Input
                label="Coupon"
                value={formik.values.coupon}
                name="coupon"
                error={formik.errors.coupon}
                onChange={formik.handleChange}
              />
              <Input
                label="Min Amount"
                value={formik.values.minAmount}
                name="minAmount"
                error={formik.errors.minAmount}
                onChange={formik.handleChange}
              />
              <Input
                label="Max Value"
                value={formik.values.maxCoupon}
                name="maxCoupon"
                error={formik.errors.maxCoupon}
                onChange={formik.handleChange}
              />
              <Input
                label="Quantity"
                value={formik.values.quantity}
                name="quantity"
                error={formik.errors.quantity}
                onChange={formik.handleChange}
              />
              <Input
                label="Validity (Days)"
                value={formik.values.validity}
                name="validity"
                error={formik.errors.validity}
                onChange={formik.handleChange}
              />
            </Box>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={setConsult.off}>
              Close
            </Button>

            <Button loading={state.creating} onClick={formik.submitForm}>
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

const FilterButton = ({ children, current, value, onSelect = () => {} }) => {
  let selected = value === current;

  return (
    <Tag
      onClick={onSelect}
      size="lg"
      rounded="full"
      cursor="pointer"
      px="20px"
      variant={selected ? "solid" : "outline"}
      colorScheme="brand"
    >
      <TagLabel>{children}</TagLabel>
    </Tag>
  );
};
