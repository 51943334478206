import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';
import 'assets/css/Fonts.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';
import { AuthContextProvider } from 'contexts/auth';

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
				<Router>
				<AuthContextProvider>
					<Switch>
						<Route path={`/auth`} component={AuthLayout} />
						<Route path={`/admin`} component={AdminLayout} />
						<Redirect from='/' to='/admin' />
					</Switch>
				</AuthContextProvider>
				</Router>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);
