import { Box, Flex, Heading, Spinner, useToast } from "@chakra-ui/react";
import apiClient from "api/index";
import Button from "components/base/button";
import Input from "components/base/input";
import Select from "components/base/select";
import TextArea from "components/base/textarea";
import Card from "components/card/Card";
import { useFormik } from "formik";
import useDispatch from "hooks/dispatch";
import React from "react";
import { useEffect } from "react";
import * as yup from "yup";

export default function Configurations() {
  const { state, dispatch } = useDispatch({ loading: true });
  const toast = useToast();

  const fetchConfig = async () => {
    try {
      dispatch({ loading: true });
      let response = await apiClient({ url: "/config" });
      dispatch({
        config: response.data,
        feeType: response.feeType,
        loading: false,
      });
    } catch (error) {
      console.log(error);
      dispatch({ loading: false });
      toast({ title: "An error occured", duration: 9000, status: "error" });
    }
  };

  const saveConfig = async (data = {}) => {
    try {
      await apiClient({ method: "post", url: "/config", data });
      toast({
        title: "Configuration saved successfully",
        duration: 9000,
        status: "success",
      });
    } catch (error) {
      console.log(error);
      dispatch({ saving: false });
      toast({ title: "An error occured", duration: 9000, status: "error" });
    }
  };

  useEffect(() => {
    fetchConfig();
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "100px" }}>
      <Box mx="auto" w="500px" maxW="full">
        {state.loading ? (
          <Flex justifyContent="center" alignItems="center" py="48">
            <Spinner />
          </Flex>
        ) : (
          <ConfigForm
            feeTypeOptions={state.feeType}
            loading={state.saving}
            saveConfig={saveConfig}
            config={state.config}
          />
        )}
      </Box>
    </Box>
  );
}

const values = {
  email: "",
  fee: "",
  feeType: "",
  exchangeRateUSD: "",
  paymentEmail: "",
  exchangeRateCAD: "",
  recieveTime: "",
  supportPhone: "",
  whatsappNumber: "",
  minAmount: "",
  zelleEmail: "",
  cashAppEmail: "",
  news: "",
  // transactionInitiatedEmail: "",
  // transactionCompleteEmail: ""
};

const ConfigForm = ({
  config = values,
  feeTypeOptions = [],
  loading,
  saveConfig = () => {},
}) => {
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Enter your email"),
    fee: yup.number().required("This field is required"),
    feeType: yup.string().required("This field is required"),
    exchangeRateUSD: yup.number().required("This field is required"),
    exchangeRateCAD: yup.number().required("This field is required"),
    recieveTime: yup.string().required("This field is required"),
    paymentEmail: yup.string().email().required("This field is required"),
    supportPhone: yup.string().required("This field is required"),
    whatsappNumber: yup.string().required("This field is required"),
    minAmount: yup.number().required("This field is required"),
    zelleEmail: yup.string().email().required("This field is required"),
    cashAppEmail: yup.string().email().required("This field is required"),
    news: yup.string(),
    // transactionInitiatedEmail: yup.string().required("This field is required"),
    // transactionCompleteEmail: yup.string().required("This field is required"),
  });

  const formik = useFormik({
    initialValues: config,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: saveConfig,
  });

  return (
    <Card>
      <Box mx="auto" w="500px" maxW="full">
        <Heading mb="6">Configurations</Heading>
        <Input
          label="Admin Email"
          value={formik.values.email}
          name="email"
          type="email"
          error={formik.errors.email}
          onChange={formik.handleChange}
        />
        <Input
          label="Interac Email"
          value={formik.values.paymentEmail}
          name="paymentEmail"
          error={formik.errors.paymentEmail}
          onChange={formik.handleChange}
        />
        {/* <Flex gap="4"> */}
          <Input
            label="Zelle Email"
            value={formik.values.zelleEmail}
            name="zelleEmail"
            error={formik.errors.zelleEmail}
            onChange={formik.handleChange}
          />

          <Input
            label="CashApp Email"
            value={formik.values.cashAppEmail}
            name="cashAppEmail"
            error={formik.errors.cashAppEmail}
            onChange={formik.handleChange}
          />
        {/* </Flex> */}

        <Input
          label="Support Phone Number"
          value={formik.values.supportPhone}
          name="supportPhone"
          error={formik.errors.supportPhone}
          onChange={formik.handleChange}
        />
        <Input
          label="WhatsApp Number"
          value={formik.values.whatsappNumber}
          name="whatsappNumber"
          error={formik.errors.whatsappNumber}
          onChange={formik.handleChange}
        />
        {/* <Flex gap="4"> */}
          <Input
            label="Fee"
            value={formik.values.fee}
            name="fee"
            error={formik.errors.fee}
            onChange={formik.handleChange}
          />

          <Select
            label="Fee Type"
            value={formik.values.feeType}
            name="feeType"
            error={formik.errors.feeType}
            options={feeTypeOptions}
            onChange={formik.handleChange}
          />
        {/* </Flex> */}
        {/* <Flex gap="4" flexDir={{base: "column", }}> */}
          <Input
            label="Exchange Rate(USD)"
            value={formik.values.exchangeRateUSD}
            name="exchangeRateUSD"
            error={formik.errors.exchangeRateUSD}
            onChange={formik.handleChange}
          />

          <Input
            label="Exchange Rate(CAD)"
            value={formik.values.exchangeRateCAD}
            name="exchangeRateCAD"
            error={formik.errors.exchangeRateCAD}
            onChange={formik.handleChange}
          />
        {/* </Flex> */}

        {/* <Flex gap="4"> */}
          <Input
            label="Minimum Ammount (CAD)"
            value={formik.values.minAmount}
            name="minAmount"
            error={formik.errors.minAmount}
            onChange={formik.handleChange}
          />

          <Input
            label="Recieve Time(mins)"
            value={formik.values.recieveTime}
            name="recieveTime"
            error={formik.errors.recieveTime}
            onChange={formik.handleChange}
          />
          <TextArea
            label="News"
            value={formik.values.news}
            name="news"
            error={formik.errors.news}
            onChange={formik.handleChange}
          />
        {/* </Flex> */}

        {/* <Box>
          <Flex flexWrap="wrap" columnGap="2">
            <span>{"{SENDER_NAME}"}</span>
            <span>{"{SENDER_EMAIL}"}</span>
            <span>{"{SENDER_PHONE}"}</span>
            <span>{"{SENDER_COUNTRY}"}</span>
          </Flex>
        </Box>
        <TextArea
          label="Transaction Initiated Email"
          value={formik.values.transactionInitiatedEmail}
          name="transactionInitiatedEmail"
          error={formik.errors.transactionInitiatedEmail}
          onChange={formik.handleChange}
        />
        <TextArea
          label="Transaction Complete Email"
          value={formik.values.transactionCompleteEmail}
          name="transactionCompleteEmail"
          error={formik.errors.transactionCompleteEmail}
          onChange={formik.handleChange}
        /> */}
        <Button onClick={formik.submitForm} loading={loading}>
          Save
        </Button>
      </Box>
    </Card>
  );
};
