import { Box } from "@chakra-ui/react";
import React, { useEffect } from "react";
import Table from "components/Table";
import useDispatch from "hooks/dispatch";
import apiClient from "api/index";
import {formateDate, getPromoAmount, getStatus} from "helpers"
import { STATUS } from "constant/index";

export default function TransactionsHistory() {
  const {state, dispatch} = useDispatch()
  // Chakra Color Mode

  const columns = [
    {
      Header: "Reference Number",
      accessor: "refNumber",
    },
    {
      Header: "Sender",
      accessor: "client",
      Cell: ({ value }) => {
        return value?.fullName ?? "";
      },
    },
    {
      Header: "Sender Phone",
      accessor: "client2",
      Cell: ({ row: { original } }) => {
        return original?.client.phone ?? "";
      },
    },
    {
      Header: "Wallet",
      accessor: "contact2",
      Cell: ({ row: { original } }) => {
        return original.contact?.wallet ?? "";
      },
    },
    {
      Header: "Amount",
      accessor: "amount",
      Cell: ({ row }) => getPromoAmount(row.original),
    },
    {
      Header: "Pay Amount",
      accessor: "paidAmount",
      Cell: ({ row }) => getPromoAmount(row.original, false),
    },

    {
      Header: "Status",
      accessor: "status",
      Cell: ({ value }) => getStatus(value).name,
    },

    {
      Header: "Date",
      accessor: "created",
      Cell: ({ value }) => {
        return formateDate(value);
      },
    }
  ];

  const getTransactions = async () => {
    try {
      dispatch({loading: true})
      const response = await apiClient({method: "get", url: "/transactions", params: {status: [STATUS.COMPLETE, STATUS.FAILED]} })
      const transactions = response.data;
      dispatch({transactions, loading: false})
    } catch (error) {
      dispatch({loading: false})
    }
  }
  useEffect(()=> {
    getTransactions();
  }, [])

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Table
        columns={columns}
        data={state.transactions}
        loading={state.loading}
      />
    </Box>
  );
}