/* eslint-disable */
import {
  Flex,
  Table as ChakraTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Text,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import Loader from "components/loader";
import React, { useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

export default function Table({
  columns: columnsData,
  data: tableData,
  loading,
}) {
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData ?? [], [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 200;

  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const headerColor = useColorModeValue("gray.500", "whiteAlpha.600");
  const textColor = useColorModeValue("gray.700", "whiteAlpha.800");
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "auto", lg: "auto" }}
    >
      <ChakraTable {...getTableProps()} minW="full" w="max-content">
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color={headerColor}
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {
                  return (
                    <Td {...cell.getCellProps()} key={index} color={textColor}>
                      {cell.render("Cell")}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </ChakraTable>

      {loading && (
        <Flex py="20" justifyContent="center" alignItems="center">
          <Loader size={40} />
        </Flex>
      )}
      {(!tableData || tableData?.length === 0) && (
        <Flex py="20" justifyContent="center" alignItems="center">
          <Text>No Data to display</Text>
        </Flex>
      )}
    </Card>
  );
}
