import { Box } from '@chakra-ui/react'
import Card from 'components/card/Card'
import React from 'react'

export default function Consult() {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card>
            
        </Card>
    </Box>
  )
}
