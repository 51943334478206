/*eslint-disable*/
import React from "react";
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";

export default function Footer() {
  let textColor = useColorModeValue("gray.400", "white");
  return (
    <Flex zIndex="3">
      <Text color={textColor} textAlign="center" w="full">
        &copy;{new Date().getFullYear()}
        &nbsp; CelaTrading. All right reserved
      </Text>
    </Flex>
  );
}
