import {
    FormControl,
    FormLabel,
    Textarea as ChakraInput,
    useColorModeValue,
    Text,
    FormHelperText,
    Box,
  } from "@chakra-ui/react";
  import React from "react";
  
  export default function TextArea({
    label,
    hideLabel,
    required,
    placeholder,
    name,
    error,
    ...rest
  }) {
    const textColor = useColorModeValue("navy.700", "white");
    const brandStars = useColorModeValue("brand.500", "brand.400");
    return (
      <FormControl>
        {!hideLabel && (
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="sm"
            fontWeight="500"
            color={textColor}
            mb="8px"
          >
            {label} {required && <Text color={brandStars}>*</Text>}
          </FormLabel>
        )}
        <Box mb="24px">
          <ChakraInput
            isRequired={required}
            // variant="auth"
            fontSize="sm"
            name={name}
            rows="8"
            ms={{ base: "0px", md: "0px" }}
            type="email"
            placeholder={placeholder}
            fontWeight="500"
            size="lg"
            {...rest}
          />
          {error && <FormHelperText color="red.500">{error}</FormHelperText>}
        </Box>
      </FormControl>
    );
  }
  