import { Button as ChakraButton, Flex } from "@chakra-ui/react";
import Loader from "components/loader";
import React from "react";

export default function Button({
  children,
  onClick = () => {},
  loading,
  loadingText = "Loading...",
  ...rest
}) {
  return (
    <ChakraButton
      fontSize="sm"
      variant="brand"
      fontWeight="500"
      w="100%"
      h="50"
      onClick={onClick}
      disabled={loading}
      {...rest}
    >
      <Flex gap="2" alignItems="center">
        {loading && <Loader color="#ffffff" />}
        {loading ? loadingText : children}
      </Flex>
    </ChakraButton>
  );
}
