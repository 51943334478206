import { createContext, useContext } from "react";
import useDispatch from "hooks/dispatch";
import apiClient from "api";
import { useHistory } from "react-router-dom";

const authContext = createContext();

export function AuthContextProvider({ children }) {
  const context = useAuthContext();
  return (
    <authContext.Provider value={context}>{children}</authContext.Provider>
  );
}

export function useAuth() {
  return useContext(authContext);
}

function useAuthContext() {
  const history = useHistory();
  const name = localStorage.getItem("name");
  const token = localStorage.getItem("token");
  const { state, dispatch } = useDispatch({
    user: { name, isAuthenticated: token !== null },
  });

  const loginUser = async (user) => {
    dispatch({ userLoading: true, loginError: null });
    const data = user;
    try {
      const response = await apiClient({
        method: "post",
        url: "/login",
        data,
      });

      dispatch({ userLoading: false });
      const userData = {
        name: response.data.fullName,
        token: response.data.token,
      };
      localStorage.setItem("token", userData.token);
      localStorage.setItem("name", userData.name);
      dispatch({ user: userData });
      history.push("/admin/dashboard");
    } catch (error) {
      dispatch({ loginError: error.message, userLoading: false });
    }
  };

  const logoutUser = () => {
    localStorage.clear();
    dispatch({ user: null });
    window.location = "/auth/signin";
  };

  return {
    ...state,
    dispatch,
    loginUser,
    logoutUser,
  };
}
